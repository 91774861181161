import React from 'react'
import { Heading } from 'theme-ui'
import Divider from '@components/Divider'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    background: t => `
      linear-gradient(
        45deg,
        ${t.colors.alpha} 32.5%,
        ${t.colors.betaDark} 50.5%)
    `,
    color: `beta`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `normal`,
    maxWidth: [`full`, null, `3/3`]
  },
  br: {
    display: ['none', null, 'block']
  }
}

const HeroContent = () => (
  <>
    <Divider space={3} />
    <Heading variant='h1' sx={styles.heading}>
      Developer. Creator.
      <br />
      Something Something.
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
      What is up, everyone, how's it going, welcome back to the chan~eh...wait,
      this isn't YouTube. What I meant to say is, pull up a chair, pour some
      coffee, and stay a while. Enjoy the rumblings of my brain, hopefully
      you'll learn something new.
    </Heading>
  </>
)

export default HeroContent
